
.navbar {
  width: 92%;
  margin: 0 auto;
  margin-bottom: 10px;
  height: 60px;
  background-color: white;
  border-radius: 10px;
}

.navbar {
  border-bottom: solid 1px #ebebeb;
}

.bottom-tab-nav {
  border-top: solid 1px #ebebeb;
}

.nav-link,
.bottom-nav-link {
  color: #1D1D1D;
}

.bottom-nav-link.active {
  color: #ff7300;
}

.bottom-tab-label {
  font-size: 1rem;
  font-family: "GilroySemiBold", sans-serif;
}

@media (max-width: 480px) {
  /* Styles for mobile devices */

  .bottom-tab-label {
    font-size: 0.7rem;
    margin-top:10px;
  }
}

@media (min-width: 480px) and (max-width: 540px) {
  .bottom-tab-label {
    font-size: 0.9rem;
    margin-top:10px;

  }
}

@media (min-width: 600px) and (max-width: 850px) {
  .bottom-tab-label {
    font-size: 1.1rem;
    margin-top:10px;

  }
  
}
