
/* #Gilroy font family */
@font-face {
  font-family: GilroyBold;
  src: url(../public/fonts/Gilroy-Bold.ttf);
}

@font-face {
  font-family: GilroyExtraBold;
  src: url(../public/fonts/Gilroy-ExtraBold.otf);
}

@font-face {
  font-family: GilroyLight;
  src: url(../public/fonts/Gilroy-Light.otf);
}

@font-face {
  font-family: GilroyMedium;
  src: url(../public/fonts/GilroyM-Medium.ttf);
}

@font-face {
  font-family: GilroySemiBold;
  src: url(../public/fonts/GilroyS-SemiBold.ttf);
}

/* #Basically font family */
@font-face {
  font-family: BasicBold;
  src: url(../public/fonts/BasicallyAMono-Bold.otf);
}
@font-face {
  font-family: BasicMedium;
  src: url(../public/fonts/BasicallyAMono-Medium.otf);
}

@font-face {
  font-family: BasicRegular;
  src: url(../public/fonts/BasicallyAMono-Regular.otf);
}
/* #Taken font family */
@font-face {
  font-family: Taken;
  src: url(../public/fonts/Taken-by-vultures.otf);
}

/* #Varela Round font family */
@font-face {
  font-family: ValeRound;
  src: url(../public/fonts/VarelaRound-Regular.ttf);
}

/* #Neue font family */
@font-face {
  font-family: Neue;
  src: url(../public/fonts/Neue.ttf);
}

@font-face {
  font-family: Mark;
  src: url(../public/fonts/MarkProBold.otf);
}

@font-face {
  font-family: Nz;
  src: url(../public/fonts//nzbaay-ttfirsneue-medium.ttf);
}


/* #Matter font family */
@font-face {
  font-family: MatterNormal;
  src: url(../public/fonts/Matter-Regular.ttf);
}

@font-face {
  font-family: MatterMedium;
  src: url(../public/fonts/Matter-Medium.ttf);
}

@font-face {
  font-family: MatterSemiBold;
  src: url(../public/fonts/Matter-SemiBold.ttf);
}

@font-face {
  font-family: MatterBold;
  src: url(../public/fonts/Matter-Bold.ttf);
}

.vale {
  font-family: "Varela Round", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear alternate;
  }
  .bouncing-element {
    animation: bounce 3s infinite;
  }
  
  .Boxes {
    animation: Boxes-logo-movement infinite 15s linear alternate;
  }

  .Illustration {
    animation: Illustration-logo-movement infinite 10s linear alternate;
  }

  .Xaxis {
    animation: Wiserve-logo-movement infinite 5s linear alternate;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes bounce {
  0%, 20%, 50%,100% {
    transform: translateY(-5);
  }
  10% {
    transform: translateY(5px);
  }
  40% {
    transform: translateY(-5px);
  }
  
  
  }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(30deg);
  }
}

@keyframes Boxes-logo-movement {
  from {
    transform: translateY(40px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes Wiserve-logo-movement {
  from {
    transform: translateX(40px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes Illustration-logo-movement {
  from {
    transform: translateX(30px);
  }
  to {
    transform: translateX(0px);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
.svg-icon{
  width: 2rem;
  height: 3rem;
}
HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
  
 
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@media only screen and (min-width: 820px) {
  .screen-size-detect {
    display: none;
    /* background-color: black; */
  }
  
}
/* element{
  -ms-overflow-style:none;
  scrollbar-width:none;
  overflow-y: scroll;
}

element::-webkit-scrollbar{
  display: none;
} */
/* Add this CSS code to your App.css file */

/* Default styles for smaller screens */

